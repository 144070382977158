import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import {jsx, css} from '@emotion/react'
import Layout from "../components/layout_under"


const RezeptLayout = ({data}) => {
    return (
        <Layout>
        
          
            {
        data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <h2>
              <Link to={`/rezepte/${node.slug}`}>
               {node.frontmatter.title}
               </Link>
            </h2>
            <p>Zutaten: {node.frontmatter.zutaten}</p>
          </article>
        ))
      }
        </Layout>
    )
}

export const query = graphql`
  {
    allMdx {
      nodes {
        frontmatter {
          zutaten
          title
        }
        id
        slug
      }
    }
  }
`

export default RezeptLayout